import React from "react"
import Index from "@pages/mt4-desktop-mac.js";

const Mt4DesktopMacJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default Mt4DesktopMacJa
